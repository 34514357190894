import { reportError } from '~/services/errors'
import { supabase } from '~/services/supabase'
import { Database, Tables } from '~/types/supabase'

const getNotifications = async () => {
  const { data: notifications, error } = await supabase
    .from('notifications')
    .select('*')
    .order('created_at', { ascending: false })

  if (error) {
    reportError(error)
    throw error
  }

  return notifications
}

const markAsRead = async (id: Tables<'notifications'>['id']) => {
  const row: Database['public']['Tables']['notifications']['Update'] = {
    id,
    read: true
  }

  const { data, error } = await supabase
    .from('notifications')
    .update(row)
    .eq('id', id)
    .select()

  if (error) {
    reportError(error)
    throw error
  }

  return data
}

const deleteNotification = async (id: Tables<'notifications'>['id']) => {
  const { error } = await supabase
    .from('notifications')
    .delete()
    .eq('id', id)

  if (error) {
    reportError(error)
    throw error
  }

  return true
}

export {
  getNotifications,
  markAsRead,
  deleteNotification
}