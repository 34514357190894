import { Tables } from '~/types/supabase'

const getBaseURL = () => {
  return process.env.VERCEL_PROJECT_PRODUCTION_URL ? 'http://localhost:3000/' : process.env.VERCEL_PROJECT_PRODUCTION_URL
}

const getSeriesLink = (seriesId: string, chapterId?: string) => {
  if(chapterId) return `/series/${seriesId}#${chapterId}`
  return `/series/${seriesId}`
}

const getChapterEditorLink = (chapter: Tables<'chapters'>) => {
  const isProgressValid = [
    'translation',
    'proofreading',
    'typesetting',
    'typeProofreading',
    'validation',
    'completed'
  ].includes(chapter.progress)
  const link = isProgressValid
      ? `/series/chapter/${chapter.id}/${chapter.progress}`
      : `/series/chapter/${chapter.id}/translation`
  return link
}

export {
  getBaseURL,
  getSeriesLink,
  getChapterEditorLink
}