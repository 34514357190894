import { Component, createEffect, createResource, createSignal } from 'solid-js'
import { NotificationEntryProps } from '~/components/notification-entry/notification-entry.interfaces'
import styles from '~/components/notification-entry/notification-entry.module.scss'
import { getSeries } from '~/services/database/series'
import { NotificationData } from '~/types/database/notifications'
import DefaultNotificationIcon from '~/assets/icons/notification.svg'
import InviteIcon from '~/assets/icons/book.svg'
import { getSeriesLink } from '~/services/link'
import { useNavigate } from '@solidjs/router'
import { deleteNotification, markAsRead } from '~/services/database/notifications'
import ConfirmationModal from '~/components/confirmation-modal/confirmation-modal'
import CheckIcon from '~/assets/icons/check.svg'
import { translations } from '~/translations'

const NotificationEntry: Component<NotificationEntryProps> = ({ entry }) => {
  const navigate = useNavigate()
  const meta: NotificationData = typeof entry.data === "string" ? JSON.parse(entry.data) : null
  const [series] = createResource(meta.seriesId, getSeries)
  const Icon = meta.type === 'invite' ? InviteIcon : DefaultNotificationIcon
  const label = meta.type === 'invite' ? translations().notifications.invite : translations().notifications.default
  const link = getSeriesLink(meta.seriesId)
  const cssClasses = () => ({
    [styles.NotificationEntry]: true,
    [styles.read]: entry.read
  })

  const [showConfirmation, setShowConfirmation] = createSignal(false)

  const onClick = async () => {
    await markAsRead(entry.id)
    setShowConfirmation(true)
  }

  const refuseInvite = () => {
    // delete permission
  }

  const goToSeries = () => {
    navigate(link)
  }

  // Self-delete if permissions to series were revoked
  createEffect(() => {
    const seriesResult = series()
    if(seriesResult === null){
      deleteNotification(entry.id)
    }
  })

  return (
    <>
      <ConfirmationModal
        open={showConfirmation()}
        setOpen={setShowConfirmation}
        title={translations().notifications.inviteConfirmation.title(series.latest?.title)}
        onConfirm={goToSeries}
        confirmIcon={CheckIcon}
        confirmLabel={translations().notifications.inviteConfirmation.confirm}
        rejectLabel={translations().notifications.inviteConfirmation.reject}
        onReject={refuseInvite}
      />
      <button
        classList={cssClasses()}
        onClick={onClick}
      >
        <Icon class={styles.icon} />
        <div class={styles.text}>
          <div class={styles.type}>
            {label}
          </div>
          <div class={styles.title}>
            {series.latest?.title}
          </div>
        </div>
        {!entry.read && (
          <div class={styles.unreadMarker} />
        )}
      </button>
    </>
  )
}

export default NotificationEntry
