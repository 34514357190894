import { Component, For, createResource, createSignal } from 'solid-js'
import styles from '~/components/notification-center/notification-center.module.scss'
import NotificationEntry from '~/components/notification-entry/notification-entry'
import { getNotifications } from '~/services/database/notifications'
import NotificationIcon from '~/assets/icons/notification.svg'

const NotificationCenter: Component = () => {
  const [notifications] = createResource(getNotifications)
  const [toggled, setToggled] = createSignal(false)

  const count = () => {
    const entries = notifications.latest ?? []
    return entries.filter(entry => !entry.read).length
  }

  const onEscapeHandler = (event: KeyboardEvent) => {
    if (event.code === 'Escape') {
      onBlurHandler()
    }
  }

  const onBlurHandler = () => {
    window.removeEventListener('click', onBlurHandler)
    window.removeEventListener('keydown', onEscapeHandler)
    onBlur()
  }

  const onBlur = () => {
    setToggled(false)
  }

  const onToggle = () => {
    setToggled(value => !value)
    if (toggled()) {
      setTimeout(() => {
        window.addEventListener('click', onBlurHandler)
        window.addEventListener('keydown', onEscapeHandler)
      })
    }
  }

  const cssClasses = () => ({
    [styles.NotificationCenter]: true,
    [styles.toggled]: toggled()
  })

  return (
    <div classList={cssClasses()}>
      <button
        onClick={onToggle}
        class={styles.toggleButton}
      >
        <NotificationIcon class={styles.icon}/>
          {count() > 0 && (
            <div class={styles.count}>
              {count()}
            </div>
          )}
      </button>
      <div class={styles.dropdown}>
        <For each={notifications.latest}>
          {entry => (
            <NotificationEntry entry={entry} />
          )}
        </For>
      </div>
    </div>
  )
}

export default NotificationCenter
