import { A } from '@solidjs/router'
import { Component } from 'solid-js'
import { HeaderProps } from '~/components/header/header.interfaces'
import styles from '~/components/header/header.module.scss'
import { user } from '~/services/database/users'
import BrandLogo from '~/assets/brand/logo-with-tagline.svg'
import BookIcon from '~/assets/icons/book.svg'
import UserIcon from '~/assets/icons/user.svg'
import NotificationCenter from '~/components/notification-center/notification-center'
import { translations } from '~/translations'

const Header: Component<HeaderProps> = ({ small }) => {
  const classList = {
    [`${styles.Header}`]: true,
    [`${styles.small}`]: small,
  }

  return (
    <div classList={classList}>
      <A href='/'>
        <BrandLogo class={styles.logo} />
      </A>
      <div class={styles.menu}>
        <A class={styles.menuEntry} href='/series'>
          <BookIcon class={styles.icon} />
          <span>{translations().header.menu.series}</span>
        </A>
      </div>
      <div class={styles.userSection}>
        {/* <SubscribeButton /> */}

        <NotificationCenter />

        <A class={styles.account} href='/account'>
          <UserIcon class={styles.icon} />
          <div class={styles.about}>
            <div class={styles.name}>
              {user.latest?.name}
            </div>
          </div>
        </A>
      </div>
    </div>
  )
}

export default Header